import http from '@/services/http';
import payment from '@/services/apiPayment';
import apiEndpoints from '@/services/apiEndpoints';

const state = {
  orderDelivery: {},
  shippingPattern: {},
  temporaryOrderInfo: [],
};

const mutations = {
  SET_ORDER_DELIVERY (state, payload) {
    state.orderDelivery = payload;
  },
  SET_SHIPPING_PATTERN (state, payload) {
    state.shippingPattern = payload;
  },
  SET_TEMPORARY_ORDER_INFO (state, payload) {
    state.temporaryOrderInfo = payload;
  },
};

const getters = {
  orderDelivery: state => state.orderDelivery,
  shippingPattern: state => state.shippingPattern,
  temporaryOrderInfo: state => state.temporaryOrderInfo,
};

const actions = {
  async getCollectionShipping ({ commit }, payload) {
    const res = await http.get(apiEndpoints.getCollectionShipping.replace('{collection_id}', payload.collection_id));
    const { data } = res;

    if (data.result) {
      commit('SET_SHIPPING_PATTERN', data.result);
    }

    return data.result;
  },
  async getAddressByPostalCode (_, payload) {
    const res = await http.get(apiEndpoints.getPostalCode.replace('{postal-code}', payload.postal_code));
    const { data } = res;

    return data.result;
  },
  async getOrderByUserId (_, payload) {
    const response = await http.get(apiEndpoints.getOrdersByUserId, payload);
    const { status, data } = response;
    if (status) {
      return data.result;
    }
    return false;
  },
  async exportPdf (_, payload) {
    return await http.downloadPdf(apiEndpoints.getReceiptPdf, payload);
  },
  async paymentByCardAndCreateOrder (_, payload) {
    const response = await http.post(apiEndpoints.paymentByCardAndCreateOrder, payload);
    const { status, data } = response;
    if (status && data.result) {
      return data.result;
    }
    return { status: false };
  },
  async getInfoBank (_) {
    const response = await http.get(apiEndpoints.getInfoBank);
    const { status, data } = response;
    if (status && data.result.status) {
      return { status: true, data: data.result };
    }
    return { status: false };
  },
  async checkRemainOnetimeAccount (_) {
    const response = await http.get(apiEndpoints.checkRemainOnetimeAccount);

    const { status, data } = response;
    if (status && data.result && data.result) {
      return { status: true, data: data.result };
    }
    return { status: false };
  },
  async paymentByBankTransferAndCreateOrder (_, payload) {
    const response = await http.post(apiEndpoints.paymentByBankTransferAndCreateOrder, payload);
    const { status, data } = response;
    if (status && data.result) {
      return data.result;
    }
    return { status: false };
  },
  async createOrder (_, payload) {
    const response = await http.post(apiEndpoints.createOrder, payload);
    const { status, data } = response;
    if (status) {
      return data.result;
    }
  },
  async getGmoToken (_, payload) {
    const response = await payment.post(payload);
    if (response.status === 200) {
      return { status: true, data: response.data };
    }
    return { status: false };
  },
  async doActionPayByFincode (_, payload) {
    const response = await http.post(apiEndpoints.payByFincodeGMO, payload);
    const { status, data } = response;
    if (status && data.result.status) {
      return { status: true, data: data.result };
    }
    return { status: false };
  },
  async getOrderInfo (_, orderId) {
    const response = await http.get(apiEndpoints.getOrderInfo.replace('{order_id}', orderId));
    const { status, data } = response;
    if (status && data.result) {
      return { status: true, data: data.result };
    }
    return { status: false };
  },
};
export default {
  actions,
  getters,
  state,
  mutations,
};
