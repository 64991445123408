export default {
  // auth
  login: '/user/login',
  logout: '/user/logout',

  // register
  registerBasic: 'user/register/basic',

  // get product
  getCollections: '/user/products/collections',

  // get info postal-code
  getPostalCode: '/postal-code/{postal-code}/address',

  // register info customer
  registerInfoCustomer: '/user/register/customer',

  // get info group
  getGroupInfoById: '/groups/get-info-by-id/{group_id}',
  getGroupByCode: 'user/groups/check-valid',
  addGroupForStudent: '/user/add-group',
  registerStudent: '/user/register-student',
  checkDuplicateName: '/user/check-duplicate',

  // get product in collection
  getProductByCollectionId: '/user/products/collections/{collection_id}/products',

  // send mail link input pass
  resetPasswordSendMail: '/user/reset_password/send_mail',

  // update pass
  settingPassword: '/user/reset_password/setting_password',
  // get collection products
  getCollectionProducts: '/user/collections/{collection_id}/set',

  // get product
  getProduct: '/user/products/{product_id}',
  checkAvailableVariants: '/user/products/variants/check-available',
  checkOrderAgiain: '/user/products/check-order-again',

  // update profile
  updateProfile: '/user/setting-profile',
  getProfileInfo: '/user/profile-info',

  // faq
  getListFaq: '/common/faqs/list',

  // common - pages
  getPageBySlug: '/common/pages/{slug}',

  // order history
  getOrdersByUserId: '/user/orders',
  getReceiptPdf: '/user/exports',

  // create my size
  createStudentSize: '/user/student-sizes/store',
  updateStudentSize: '/user/student-sizes/update/{student_id}',
  getStudentSizeById: '/user/student-sizes/detail/{student_id}',

  // cart
  addToCart: '/user/carts',
  getListCartItems: 'ecommerce/cart',
  updateQuantity: 'ecommerce/cart/update-quantity',
  deleteCartItem: 'ecommerce/cart/delete/{id}',
  getCollectionInfo: 'ecommerce/cart/category/{id}',

  // payment
  paymentByCardAndCreateOrder: '/user/payment-by-card-and-create-order',
  changeStatusPaymentOrderToPendingAndMethodPayment: 'user/order/chang-status-pending-and-method-payment/{order_id}',
  getInfoBank: 'user/info-bank',
  checkRemainOnetimeAccount: 'user/check-remain-onetime-account',
  paymentByBankTransferAndCreateOrder: 'user/payment-by-bank-transfer-and-create-order',
  getGmoSecretKeyForOrder: 'user/get-gmo-secret-for-order',
  payByFincodeGMO: 'user/pay-by-fincode-gmo',
  getOrderInfo: 'user/orders/{order_id}',

  // call api to gmo
  getGmoToken: 'tokens/cards',

  // delivery-checkout
  getCollectionShipping: '/user/collections/{collection_id}/shipping',

  // order
  createOrder: '/user/orders',

  // get student
  getStudentForCustomerId: 'user/students',
};
